.DataPane {
    background-color: rgb(216, 144, 235);
    display: flex;
    flex-direction: column-reverse;
    width: 250px;
    height: fit-content;
}

#existingData {
    width: 100%;
}

#uploadNew {
    width: 100%;
}

#classOptions {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}
.Tray {
    background-color: lightblue;
    width: 20%;
    margin: 0%;
    padding: 0%;
}

button {
    margin: 10px;
}

